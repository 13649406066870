import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap"
import { RiArrowRightSLine } from "react-icons/ri"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import SEO from "../components/seo"

const CenterDiv = styled("div")`
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ThePage = () => {
  const data = useStaticQuery(graphql`
    query contactQuery {
      top: file(relativePath: { eq: "contact-1-01.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="聯絡櫻花 - 櫻花家居服務" description="" />
      <div
        css={css`
          max-width: 800px;
          margin: 0 auto;
          padding: 80px 0;
          align-items: center;
        `}
      >
        <Container>
          <Row>
            <Col sm={6} xs={12}>
              <CenterDiv>
                <div
                  css={css`
                    display: block;
                    width: 200px;
                    height: 200px;
                  `}
                >
                  <Img fluid={data.top.childImageSharp.fluid} />
                </div>
              </CenterDiv>
            </Col>
            <Col>
              <CenterDiv>
                <Link to="/booking" className="button">
                  <b>按此預約</b>
                  <span className="icon -right">
                    <RiArrowRightSLine />
                  </span>
                </Link>
              </CenterDiv>
              <CenterDiv>
                <a
                  href="https://api.whatsapp.com/send?phone=85295208808"
                  className="button"
                >
                  <b>按此Whatsapp 查詢</b>
                  <span className="icon -right">
                    <RiArrowRightSLine />
                  </span>
                </a>
              </CenterDiv>
            </Col>
          </Row>
          <Row>
            <Col fluid>
              <div
                css={css`
                  color: #ff2ea0;
                  text-align: center;
                `}
              >
                <h3>聯絡方法</h3>
              </div>
            </Col>
          </Row>
          <Row>
            <Col fluid>
              <div>
                WhatsApp：
                <a href="https://api.whatsapp.com/send?phone=85295208808">
                  9520 8808
                </a>
                <br />
                電郵：
                <a href="mailto:info@sakura-home.com.hk">
                  info@sakura-home.com.hk
                </a>
                <br />
                公司電話： <a href="tel:+85228992995">2899 2995</a>
                <br />
                公司傳真： 2899 2994
                <br />
              </div>
              <br />
              <div>
                地址： 香港九龍開源道72-80號溢財中心1202A室（觀塘地鐵站B1出口）
              </div>
            </Col>
          </Row>
          <Row>
            <iframe
              width="600"
              height="450"
              frameBorder="0"
              style={{
                margin: "0 auto",
                border: "none",
                overflow: "hidden",
              }}
              src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJXac8-k8BBDQRCD2zfDwB8qg&key=AIzaSyCZqVYQyHbFygxH5ORBgRnWD9ajubG9HG8"
              allowFullScreen
            ></iframe>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}
export default ThePage
